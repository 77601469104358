<div>
    <form class="form-inline main-container">
        <div class="form-group">
            <div class="input-group mx-4">
                <mat-form-field>
                    <input matInput placeholder="Filtrar por fecha" [satDatepicker]="picker" [formControl]="date" [readonly]="true">
                    <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
                    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
                </mat-form-field>
            </div>
            <div class="input-group mx-4 float-right">
                <button class="btn btn-outline-secondary" (click)="showData()" type="button">Buscar</button>
            </div>

        </div>
    </form>
    <div class="main-container">
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType" [colors]="barChartColors"></canvas>
    </div>

    <br>

    <div class="mat-elevation-z8 margin_top">
        <table mat-table [dataSource]="dataSource" class="table">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Fecha</th>
                <td mat-cell *matCellDef="let element"> {{element.date|date:'dd/MM/yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef>Nº Pedidos</th>
                <td mat-cell *matCellDef="let element"> {{element.orderCount}} </td>
            </ng-container>
            <ng-container matColumnDef="preptime">
                <th mat-header-cell *matHeaderCellDef>Tiempo medio</th>
                <td mat-cell *matCellDef="let element">{{element.avgTime| tiempomedio}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>
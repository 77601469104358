import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserModel } from '../models/user.model';
import { LoginModel } from '../models/login.model';
import { LoginDTO } from '../../../../backend/src/user/dto/login.dto';
import { PrefsService } from './prefs.service';
import { RequestOptions } from 'https';


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private url = environment.API_URL;

  constructor(
    private httpClient: HttpClient,
    private preferences: PrefsService
  ) { }

  getAll() {
    return this.httpClient.get<UserModel[]>(this.url + 'user', this.getAuth());
  }

  add(user: UserModel) {
      return this.httpClient.post<UserModel>(this.url + 'user', user, this.getAuth());
  }

  getById(id: string) {
      return this.httpClient.get<UserModel>(this.url + 'user/' + id, this.getAuth());
  }

  update(id: string, user: UserModel) {
      return this.httpClient.put<UserModel>(this.url + 'user/' + id, user, this.getAuth());
  }

  delete(id: string) {
      return this.httpClient.delete<UserModel>(this.url + 'user/' + id, this.getAuth());
  }

  login(login: LoginDTO) {
    return this.httpClient.post<LoginModel>(this.url + 'user/login', login);
  }

  private getAuth() {
    const header = new HttpHeaders()
      .set('Authorization', `Bearer ${this.preferences.getToken()}`);
    return {
       headers: header,
    };
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ByDayDTO } from '../models/byday.dto';
import { environment } from '../../environments/environment';
import { ByDayResDTO } from '../models/byday.res.dto';

@Injectable({
  providedIn: 'root'
})
export class EditsService {
  private url = environment.API_URL;

  constructor( private httpClient: HttpClient) { }

  getEditedOrders(body: ByDayDTO) {
    return this.httpClient.post<ByDayResDTO[]>(this.url + 'orderEdit/editedorders', body);
  }
}

<form [formGroup]="userForm" (ngSubmit)="onSubmit()">

    <div class="dialog-header">
        <h4 class="dialog-title pb-4" id="dialog-basic-title">{{data.title}}</h4>
    </div>

    <div class="form-group">
        <mat-form-field class="full-width">
            <input matInput formControlName="name" placeholder="Nombre" type="text">
            <mat-error *ngIf="userForm.get('name').hasError('required')">
                Campo <strong>obligatorio</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-group">
        <mat-form-field class="full-width">
            <input matInput formControlName="email" placeholder="Email">
            <mat-error *ngIf="userForm.get('email').hasError('required')">
                Campo <strong>obligatorio</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-group">
        <mat-form-field class="full-width">
            <input matInput formControlName="password" placeholder="Contraseña" [type]="hide1 ? 'password' : 'text'">
            <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="userForm.get('password').hasError('required')">
                Campo <strong>obligatorio</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-group">
        <mat-form-field class="full-width">
            <input matInput formControlName="password2" placeholder="Repita contraseña" [type]="hide2 ? 'password' : 'text'">
            <mat-icon matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <mat-error *ngIf="!coinciden">
            Las contraseñas deben ser <strong>iguales</strong>
        </mat-error>
    </div>

    <div class="form-group">
        <mat-checkbox formControlName="active">Activo</mat-checkbox>
    </div>

    <div class="form-group">
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close>Cancelar</button>
            <button mat-raised-button color="primary" type="submit" [disabled]="!userForm.valid">Guardar</button>
        </div>
    </div>
</form>
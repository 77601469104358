import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { UserModel } from '../../models/user.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { UsereditComponent } from '../useredit/useredit.component';
import { AlertType, Alert } from '../../models/Alert';
import { AlertService } from '../../services/alert.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public dataUser = new MatTableDataSource<UserModel>([]);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  public displayedColumns: string[] = ['name', 'email', 'active', 'actions'];

  constructor(
    private userService: UsersService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getData();
    this.dataUser.paginator = this.paginator;
  }

  newUser() {
    const dialogRef = this.dialog.open(UsereditComponent, {
      width: '500px',
      data: { element: null, title: 'Añadir usuario'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.alertService.newAlert(new Alert(AlertType.SUCCESS, 'Se ha guardado correctamente'));
        this.getData();
      } else {
        this.alertService.newAlert(new Alert(AlertType.DANGER, 'Se ha producido un error al guardar el actor'));
      }
     });
  }

  editUser(id: string) {
    this.userService.getById(id).subscribe(zone => {
      const dialogRef = this.dialog.open(UsereditComponent, {
      width: '600px',
      data: { element: zone, title: 'Editar usuario'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.alertService.newAlert(new Alert(AlertType.SUCCESS, 'Se ha actualizado correctamente'));
        this.getData();
      } else {
        this.alertService.newAlert(new Alert(AlertType.DANGER, 'Se ha producido un error al actualizar'));
      }
     });
    });
  }

  deleteElement(id: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '900px',
      data: { sms: 'el usuario', id: id, service: this.userService}
    });
    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case true:
          this.alertService.newAlert(new Alert(AlertType.SUCCESS, 'Se ha eliminado correctamente'));
          this.updateTables(id);
          break;
        case false:
          this.alertService.newAlert(new Alert(AlertType.DANGER, 'Se ha producido un error al borrar'));
          break;
        default:
          break;
      }
    });
  }

  updateTables(id: string) {
    this.dataUser.data = this.dataUser.data.filter(data => data._id !== id);
  }

  private getData() {
    this.userService.getAll().subscribe(data => {
      this.dataUser.data = data;
    });
  }

}

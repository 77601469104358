/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./delete-dialog.component";
import * as i8 from "../../services/alert.service";
var styles_DeleteDialogComponent = [i0.styles];
var RenderType_DeleteDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteDialogComponent, data: {} });
export { RenderType_DeleteDialogComponent as RenderType_DeleteDialogComponent };
export function View_DeleteDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" \u00BFDesea borrar ", "?\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dialogRef.close(i1.ɵnov(_v, 7).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(7, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancelar"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Borrar"]))], function (_ck, _v) { var currVal_5 = ""; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.sms; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 6).disabled || null); var currVal_2 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 7).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 7).type; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 10).disabled || null); var currVal_7 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_6, currVal_7); }); }
export function View_DeleteDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-dialog", [], null, null, null, View_DeleteDialogComponent_0, RenderType_DeleteDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.DeleteDialogComponent, [i8.AlertService, i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var DeleteDialogComponentNgFactory = i1.ɵccf("app-delete-dialog", i7.DeleteDialogComponent, View_DeleteDialogComponent_Host_0, {}, {}, []);
export { DeleteDialogComponentNgFactory as DeleteDialogComponentNgFactory };

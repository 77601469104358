import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OrdersService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.url = environment.API_URL;
    }
    getByDay(body) {
        return this.httpClient.post(this.url + 'order/byday', body);
    }
    getPreparingTime(body) {
        return this.httpClient.post(this.url + 'order/preparingtime', body);
    }
    getDeliveredOrders(body) {
        return this.httpClient.post(this.url + 'order/deliveredorders', body);
    }
}
OrdersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrdersService_Factory() { return new OrdersService(i0.ɵɵinject(i1.HttpClient)); }, token: OrdersService, providedIn: "root" });

import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import { PrefsService } from './services/prefs.service';

@Injectable({
    providedIn: 'root'
  })
export class AuthGuard implements CanActivate {
    constructor(
        private preferences: PrefsService,
        private router: Router
    ) {
        console.log("preferences", preferences);
        console.log("router", router);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const redirectUrl = route['_routerState']['url'];
        if (this.preferences.isLogged()) {
          return true;
        }

        this.router.navigateByUrl(
          this.router.createUrlTree(
            ['/login'], {
              queryParams: {
                redirectUrl
              }
            }
          )
        );
        return false;
    }
}

<div class="card mt-5" style="margin:auto;width: 600px;">
    <div class="card-body">
        <h5 class="card-title">Login</h5>
        <div class="form-group">
            <label for="email">Email</label>
            <input type="text" class="form-control" id="email" placeholder="Email Address" [(ngModel)]="email">
        </div>
        <div class="form-group">
            <label for="email">Password</label>
            <input type="password" class="form-control" id="password" placeholder="Password" [(ngModel)]="password">
        </div>
        <button type="submit" class="btn btn-primary" (click)="tryLogin()">Login</button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from '../models/Alert';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  // Observable string sources
  private newAlertSource = new Subject<Alert>();

  // Observable string streams
  newAlert$ = this.newAlertSource.asObservable();

  // Service message commands
  newAlert(alert: Alert) {
    this.newAlertSource.next(alert);
  }
}

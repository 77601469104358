import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EditsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.url = environment.API_URL;
    }
    getEditedOrders(body) {
        return this.httpClient.post(this.url + 'orderEdit/editedorders', body);
    }
}
EditsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EditsService_Factory() { return new EditsService(i0.ɵɵinject(i1.HttpClient)); }, token: EditsService, providedIn: "root" });

import * as i0 from "@angular/core";
const TOKEN = 'TOKEN';
export class PrefsService {
    setToken(token) {
        localStorage.setItem(TOKEN, token);
    }
    getToken() {
        const token = localStorage.getItem(TOKEN);
        return token ? token : '';
    }
    isLogged() {
        return localStorage.getItem(TOKEN) != null;
    }
}
PrefsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrefsService_Factory() { return new PrefsService(); }, token: PrefsService, providedIn: "root" });

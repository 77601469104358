import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./prefs.service";
export class UsersService {
    constructor(httpClient, preferences) {
        this.httpClient = httpClient;
        this.preferences = preferences;
        this.url = environment.API_URL;
    }
    getAll() {
        return this.httpClient.get(this.url + 'user', this.getAuth());
    }
    add(user) {
        return this.httpClient.post(this.url + 'user', user, this.getAuth());
    }
    getById(id) {
        return this.httpClient.get(this.url + 'user/' + id, this.getAuth());
    }
    update(id, user) {
        return this.httpClient.put(this.url + 'user/' + id, user, this.getAuth());
    }
    delete(id) {
        return this.httpClient.delete(this.url + 'user/' + id, this.getAuth());
    }
    login(login) {
        return this.httpClient.post(this.url + 'user/login', login);
    }
    getAuth() {
        const header = new HttpHeaders()
            .set('Authorization', `Bearer ${this.preferences.getToken()}`);
        return {
            headers: header,
        };
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PrefsService)); }, token: UsersService, providedIn: "root" });

import * as i0 from "@angular/core";
import * as i1 from "./services/prefs.service";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(preferences, router) {
        this.preferences = preferences;
        this.router = router;
        console.log("preferences", preferences);
        console.log("router", router);
    }
    canActivate(route, state) {
        const redirectUrl = route['_routerState']['url'];
        if (this.preferences.isLogged()) {
            return true;
        }
        this.router.navigateByUrl(this.router.createUrlTree(['/login'], {
            queryParams: {
                redirectUrl
            }
        }));
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.PrefsService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });

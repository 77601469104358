import { Component, OnInit } from '@angular/core';
import { PrefsService } from '../../services/prefs.service';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { LoginDTO } from '../../models/login.dto';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  email = '';
  password = '';

  constructor(
    private service: UsersService,
    private preferences: PrefsService,
    private router: Router) {
  }

  tryLogin() {
    let login = new LoginDTO(
      this.email,
      this.password
    );

    this.service.login(
      login
    )
      .subscribe(
        r => {
          if (r.access_token) {
            this.preferences.setToken(r.access_token);
            this.router.navigateByUrl('/home');
          }
        },
        r => {
          alert(r.error.error);
        });
  }
}

<div class="body_container container-fluid">
    <div class="fisrt_row row pt-2">
        <div class="col">
            <button class="float-right" (click)="newUser()">Nuevo Usuario</button>
        </div>
    </div>

    <div class="second_row row pt-1">
        <div class="col">
            <h3>Lista de usuarios</h3>

            <div class="mat-elevation-z8 margin_top">
                <table mat-table [dataSource]="dataUser" class="table">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Nombre</th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef>Activo</th>
                        <td mat-cell *matCellDef="let element"> <input type="checkbox" [checked]="element.active" disabled="true"> </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Acciones</th>
                        <td mat-cell *matCellDef="let element">
                            <button class="mr-1" (click)="deleteElement(element._id)"><i class="far fa-trash-alt"></i></button>
                            <button (click)="editUser(element._id)"><i class="fas fa-edit"></i></button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>
export enum AlertType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark'
}

export class Alert {

    constructor(type: AlertType, msg: string) {
        this.type = type;
        this.msg = msg;
    }
    type: AlertType;
    msg: string;
}

import {Injectable} from '@angular/core';

const TOKEN = 'TOKEN';

@Injectable({
  providedIn: 'root'
})
export class PrefsService {

  setToken(token: string): void {
    localStorage.setItem(TOKEN, token);
  }

  getToken(): string {
    const token = localStorage.getItem(TOKEN);
    return token ? token : '';
  }

  isLogged() {
    return localStorage.getItem(TOKEN) != null;
  }
}
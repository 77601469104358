import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class AlertService {
    constructor() {
        // Observable string sources
        this.newAlertSource = new Subject();
        // Observable string streams
        this.newAlert$ = this.newAlertSource.asObservable();
    }
    // Service message commands
    newAlert(alert) {
        this.newAlertSource.next(alert);
    }
}
AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(); }, token: AlertService, providedIn: "root" });

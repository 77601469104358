import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData, IComparable } from '../../interfaces/DialogData';
import { UsersService } from '../../services/users.service';
import { UserModel } from '../../models/user.model';
import { rePasswordValidator } from '../../directives/password-validator.directive';
import { AlertType, Alert } from '../../models/Alert';

@Component({
  selector: 'app-useredit',
  templateUrl: './useredit.component.html',
  styleUrls: ['./useredit.component.css']
})
export class UsereditComponent implements OnInit {
  userForm: FormGroup;
  data: DialogData;
  submitMsg;
  coinciden = true;
  nuevoUsuario = false;
  hide1 = true;
  hide2 = true;

  constructor(private userService: UsersService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<UsereditComponent>,
    @Inject(MAT_DIALOG_DATA) public datas: DialogData) {
      console.log('Los que me llega => ', datas.element);
      this.data = datas;
  }

  ngOnInit() {
    if (!this.data.element) {
      this.nuevoUsuario = true;
      this.data.element = new UserModel();
      this.userForm = new FormGroup({
          'name': new FormControl( '', [Validators.required]),
          'email': new FormControl( '', [Validators.required]),
          'password': new FormControl( '', [Validators.required]),
          'password2': new FormControl( '', [Validators.required, rePasswordValidator()]),
          'active': new FormControl(true),
          });
    } else {
        this.userForm = new FormGroup({
        'name': new FormControl( (this.data.element.name ? this.data.element.name : ''), [Validators.required]),
        'email': new FormControl( (this.data.element.email ? this.data.element.email : ''), [Validators.required]),
        'password': new FormControl( ''),
        'password2': new FormControl( ''),
        'active': new FormControl(this.data.element.active),
        });
    }
  }

  onSubmit() {
    this.submitMsg = null;
    if (!this.nuevoUsuario && this.userForm.value.password !== this.userForm.value.password2) {
      this.coinciden = false;
      this.alertService.newAlert(new Alert(AlertType.DANGER, 'Ambas contraseñas deben coincidir'));
    } else {
        this.actualizarUsuario();
        if (!this.data.element._id) {
          this.userService.add(this.data.element).subscribe(data => {
            this.dialogRef.close(true);
          },  (error: any) => {
            this.dialogRef.close(false);
          });
        } else {
          this.userService.update(this.data.element._id, this.data.element).subscribe(() => {
            this.dialogRef.close(true);
          },  (error: any) => {
            this.dialogRef.close(false);
          });
      }
    }
  }

  actualizarUsuario() {
    this.data.element.name = this.userForm.value.name;
    this.data.element.email = this.userForm.value.email;
    if (this.userForm.value.password) {
      this.data.element.password = this.userForm.value.password;
    }
    this.data.element.active = this.userForm.value.active;
  }

  compareTo(c1: IComparable, c2: IComparable): boolean {
    return c1 && c2 ? c1._id === c2._id : c1 === c2;
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tiempomedio'
})
export class TiempomedioPipe implements PipeTransform {

  transform(seconds: number): any {
    let hours   = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - (hours * 3600)) / 60);
    let secs = Math.floor(seconds - (hours * 3600) - (minutes * 60));

    let resp = `${ hours < 10 ? '0' + hours : hours }h
      ${ minutes < 10 ? '0' + minutes : minutes }m
      ${ secs < 10 ? '0' + secs : secs }s`;

    return resp;
  }

}

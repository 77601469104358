import { Component, OnInit, ViewChild } from '@angular/core';
import { ByDayDTO } from '../../models/byday.dto';
import { OrdersService } from '../../services/orders.service';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import { ByDayResDTO } from '../../models/byday.res.dto';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';

@Component({
  selector: 'app-ordersbyday',
  templateUrl: './ordersbyday.component.html',
  styleUrls: ['./ordersbyday.component.css']
})
export class OrdersbydayComponent implements OnInit {
  public date;
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [{data: [], label: 'Nº Pedidos'},{data: [], label: 'Total pedidos'}];
  public barChartColors = [{ // first color
    backgroundColor: '#d8dd29',
  },
  { // second color
    backgroundColor: '#9CA20B',
  }];
  public emptyData = true;
  public displayedColumns: string[] = ['date', 'count', 'amount'];
  public dataSource = new MatTableDataSource<ByDayResDTO>([]);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private orderService: OrdersService,
  ) {}

  ngOnInit() {
    this.date = new FormControl({begin: moment().utc().startOf('M').toDate(), end: moment().utc().endOf('M').toDate()});
    this.showData();
    this.dataSource.paginator = this.paginator;
  }

  showData() {
    const start = new Date(
      Date.UTC(this.date.value.begin.getFullYear(), this.date.value.begin.getMonth(), this.date.value.begin.getDate())
    );

    const end = new Date(
      Date.UTC(this.date.value.end.getFullYear(), this.date.value.end.getMonth(), this.date.value.end.getDate())
    );

    const byDay = new ByDayDTO(start, end);
    this.orderService.getByDay(byDay).subscribe(response => {
        if (!response || response.length == 0) {
          this.emptyData = true;
          return;
        }

        this.emptyData = false;
        this.dataSource.data = response;
        this.barChartLabels = [];
        this.barChartData[0].data = [];

        for (let input of response) {
          let d = moment(input.date).format('L');
          this.barChartLabels.push(d);
          this.barChartData[0].data.push(input.count);
          this.barChartData[1].data.push(input.amount);
        }
    }, error => {
     console.error("ERROR: Unexpected response");
    });
  }
}

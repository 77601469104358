import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, FormControl } from '@angular/forms';

export function rePasswordValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.parent) {
          const repassword = control;
          const password = control.parent.get('password');
          return password && repassword && (
              password.value !== repassword.value ||
              password.value.toString().trim() === '' ||
              repassword.value.toString().trim() === ''
          ) ? {'passwordCheck': {value: control.value}} : null;
      } else {
          return null;
      }


  };
}
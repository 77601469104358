import { Component, OnInit, Inject } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDataDelete } from '../../interfaces/DialogData';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent {

  data: DialogDataDelete;

  constructor(public alertService: AlertService,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public datas: DialogDataDelete) {
      this.data = datas;
    }

  onClick(): void {
    this.data.service.delete(this.data.id).subscribe(() => {
      this.dialogRef.close(true);
    },  (error: any) => {
      this.dialogRef.close(false);
    });
  }

}

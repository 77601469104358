import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderModel } from '../models/order.model';
import { ByDayDTO } from '../models/byday.dto';
import { environment } from '../../environments/environment';
import { ByDayResDTO } from '../models/byday.res.dto';
import { DeliveredDTO } from '../models/delivered.dto';
import { PrepTimeDTO } from '../models/preptime.dto';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private url = environment.API_URL;

  constructor( private httpClient: HttpClient) { }

  getByDay(body: ByDayDTO) {
    return this.httpClient.post<ByDayResDTO[]>(this.url + 'order/byday', body);
  }

  getPreparingTime(body: ByDayDTO) {
    return this.httpClient.post<PrepTimeDTO[]>(this.url + 'order/preparingtime', body);
  }
  
  getDeliveredOrders(body: ByDayDTO) {
    return this.httpClient.post<DeliveredDTO[]>(this.url + 'order/deliveredorders', body);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { OrdersbydayComponent } from './components/ordersbyday/ordersbyday.component';
import { OrderspreptimeComponent } from './components/orderspreptime/orderspreptime.component';
import { OrderseditedComponent } from './components/ordersedited/ordersedited.component';
import { OrdersdeliveredComponent } from './components/ordersdelivered/ordersdelivered.component';
import { UsersComponent } from './components/users/users.component';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MyMaterialModule} from './material-module';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import * as moment from 'moment';
import { UsereditComponent } from './components/useredit/useredit.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { HttpErrorInterceptor } from './services/interceptors/httpErrorInterceptor';
import { TiempomedioPipe } from './pipes/tiempomedio.pipe';

registerLocaleData(localeEs);
moment.locale('es');

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    OrdersbydayComponent,
    OrderspreptimeComponent,
    OrderseditedComponent,
    OrdersdeliveredComponent,
    UsersComponent,
    UsereditComponent,
    DeleteDialogComponent,
    LoginComponent,
    TiempomedioPipe,

  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(ROUTES, {useHash: true}),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    MatNativeDateModule,
    MyMaterialModule
  ],
  entryComponents:[
    UsereditComponent,
    DeleteDialogComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <a class="navbar-brand" href="#">
        <img src="assets/img/supermercados-mas.svg" height="30" class="d-inline-block align-top" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['ordersbyday']">Pedidos por día</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['ordersedited']">Pedidos editados</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['orderspreptime']">Tiempo de preparación</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['ordersdelivered']">Pedidos entregados</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['users']">Usuarios</a>
            </li>
        </ul>
    </div>
</nav>
import { HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class HttpErrorInterceptor {
    constructor(router) {
        this.router = router;
    }
    intercept(req, next) {
        return next.handle(req).pipe(tap(() => { }, (err) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status !== 401) {
                    return;
                }
                this.router.navigate(['login']);
            }
        }));
    }
}
HttpErrorInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpErrorInterceptor_Factory() { return new HttpErrorInterceptor(i0.ɵɵinject(i1.Router)); }, token: HttpErrorInterceptor, providedIn: "root" });
